<template>
  <div class="uk-section uk-margin-large-top">
    <div class="uk-container">
      <div uk-filter="target: .js-filter">
        <ul class="uk-subnav uk-subnav-pill">
          <li class="uk-active" uk-filter-control><a href="#">All</a></li>
          <li uk-filter-control="[data-color='europe']">
            <a href="#">Europe</a>
          </li>
          <li uk-filter-control="[data-color='america']">
            <a href="#">AMERICA</a>
          </li>
          <li uk-filter-control="[data-color='asia']">
            <a href="#">ASIA</a>
          </li>
          <li uk-filter-control="[data-color='australia']">
            <a href="#">AUSTRALIA</a>
          </li>
        </ul>

        <ul
          class="js-filter uk-child-width-1-2 uk-child-width-1-3@m uk-text-center"
          uk-grid
        >
          <li data-color="europe">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-milan">
                <img
                  src="@/assets/img/Milan.jpg"
                  alt="Milan image"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text">Milan, Italy</a>
                </div>
              </router-link>
            </div>
          </li>
          <li data-color="america">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-new-york-city">
                <img
                  src="@/assets/img/New-York-City.jpeg"
                  alt="New York City image"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text"
                    >New York City, USA</a
                  >
                </div>
              </router-link>
            </div>
          </li>
          <li data-color="asia">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-tokyo">
                <img
                  src="@/assets/img/Tokyo.jpeg"
                  alt="London image"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text">Tokyo, Japan</a>
                </div>
              </router-link>
            </div>
          </li>
          <li data-color="europe">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-london">
                <img
                  src="@/assets/img/London.jpeg"
                  alt="London image"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text">London, UK</a>
                </div>
              </router-link>
            </div>
          </li>

          <li data-color="asia">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-bangkok">
                <img
                  src="@/assets/img/Bangkok.jpeg"
                  alt="London image"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text"
                    >Bangkok, Thailand</a
                  >
                </div>
              </router-link>
            </div>
          </li>

          <li data-color="america">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-seattle">
                <img
                  src="@/assets/img/Seattle.jpeg"
                  alt="London image"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text">Seattle, USA</a>
                </div>
              </router-link>
            </div>
          </li>

          <li data-color="europe">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-paris">
                <img
                  src="@/assets/img/Paris.jpeg"
                  alt="Paris image"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text">Paris, France</a>
                </div>
              </router-link>
            </div>
          </li>
          <li data-color="america">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-los-angeles">
                <img
                  src="@/assets/img/LosAngeles.jpeg"
                  alt="Los Angeles image"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text"
                    >Los Angeles, USA</a
                  >
                </div>
              </router-link>
            </div>
          </li>
          <li data-color="america">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-san-francisco">
                <img
                  src="@/assets/img/SanFrancisco.jpeg"
                  alt="San Francisco image"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text"
                    >San Francisco, USA</a
                  >
                </div>
              </router-link>
            </div>
          </li>
          <li data-color="europe">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-malaga">
                <img
                  src="@/assets/img/Malaga.jpeg"
                  alt="Malaga image"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text">Málaga, Spain</a>
                </div>
              </router-link>
            </div>
          </li>
          <li data-color="europe">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-lisbon">
                <img
                  src="@/assets/img/Lisbon.jpeg"
                  alt="Lisbon image"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text"
                    >Lisbon, Portugal</a
                  >
                </div>
              </router-link>
            </div>
          </li>
          <li data-color="europe">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-seville">
                <img
                  src="@/assets/img/Seville.jpeg"
                  alt="Seville image"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text"
                    >Seville, Spain</a
                  >
                </div>
              </router-link>
            </div>
          </li>

          <li data-color="asia">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-hong-kong">
                <img
                  src="@/assets/img/Hong-Kong.jpeg"
                  alt="Hong Kong image"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text"
                    >Hong Kong, China</a
                  >
                </div>
              </router-link>
            </div>
          </li>
          <li data-color="europe">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-florence">
                <img
                  src="@/assets/img/Florence.jpeg"
                  alt="Hong Kong image"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text"
                    >Florence, Italy</a
                  >
                </div>
              </router-link>
            </div>
          </li>
          <li data-color="america">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-boston">
                <img
                  src="@/assets/img/Boston.jpeg"
                  alt="Boston image"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text">Boston, USA</a>
                </div>
              </router-link>
            </div>
          </li>

          <li data-color="america">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-chicago">
                <img
                  src="@/assets/img/Chicago.jpeg"
                  alt="Chicago"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text">Chicago, USA</a>
                </div>
              </router-link>
            </div>
          </li>
          <li data-color="australia">
            <div
              class="uk-card uk-card-default uk-card-body uk-padding-remove-bottom"
            >
              <router-link to="/best-negroni-in-sydney">
                <img
                  src="@/assets/img/Sydney.jpeg"
                  alt="Chicago"
                  class="image"
                  style="width:100%"
                />
                <div class="uk-card-footer">
                  <a href="#" class="uk-button uk-button-text"
                    >Sydney, Australia</a
                  >
                </div>
              </router-link>
            </div>
          </li>

          <!-- li data-color="australia">
            <div class="uk-card uk-card-secondary uk-card-body uk-padding-remove-bottom">Item</div>
          </li -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Badass Negroni',
    // all titles will be injected into this template
    titleTemplate: '%s - The Best Negronis and Top Cocktail Bars',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Check out our curated list of the best places to drink Negroni cocKtails. Organized by Continents, Countries, Cities and Top Bars'
      }
    ]
  }
}
</script>
